import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, CTA } from "../components";

const ContactLenses = ({
  data: {
    strapiDsrAnnouncements: { content, header, summary, topic },
  },
}) => {
  return (
    <PageLayout
      content={content}
      title={header}
      subtitle={topic}
      intro={summary}
      flourish={1}
    >
      <CTA />
    </PageLayout>
  );
};

export default ContactLenses;

export const query = graphql`
  query announcementsPageQuery {
    strapiDsrAnnouncements {
      content
      header
      summary
      topic
    }
  }
`;
